import React, { useState, useEffect } from 'react';
import FREELANCE_PROJECTS from '../components/freelance_projects';
// import bbunny from '../img/Pepsi-PressPlay.jpg';
import rcups from '../img/Rcups001.png';
import API from '../img/APIs.jpg';
import devops from '../img/devops.webp';

const projects = [
    // {
    //     id: 1,
    //     title: 'Press Play On Summer',
    //     type: 'professional',
    //     description: "A Sweepstakes program, celebrating Bad Bunny's award as Apple Music's Artist of the Year",
    //     tags: ['PHP', 'MySQL', 'Code Redemption'],
    //     img: bbunny,
    //     case_study_overview: 'Press Play on Summer (PPOS) was a promotional campaign celebrating Bad Bunny’s recognition as Apple Music’s Artist of the Year. The site invited fans to participate in a sweepstakes, offering prizes and three months of free Apple Music. With its engaging theme and high-profile nature, PPOS attracted significant traffic but also became a target for automated bots attempting to manipulate the giveaway.',
    //     case_study: 'Despite these challenges, PPOS successfully delivered a seamless and enjoyable experience for thousands of users. The site remained stable throughout the campaign, maintaining high performance and zero downtime. By balancing usability with strong security measures, I ensured the project met both technical and business goals. Apple Music was pleased with the results, recognizing the effectiveness of the solution in preventing fraud and engaging users meaningfully.',
    // },

    {
        id: 1,
        title: 'Reward Cups',
        type: 'professional',
        description: 'Promoting the launch of a new innovative product',
        tags: ['PHP', 'MySQL', 'AWS'],
        img: rcups,
        case_study_overview: 'Reward Cups was a limited-time promotional site designed to support the launch of an innovative product. Built using PHP and MySQL, the site aimed to engage customers by providing coupons and advertisements on large drink cups, which directed users to the site. This project also marked the beginning of my experience working with AWS, as I began integrating cloud services to improve scalability and performance.',
        case_study: 'The core feature of the site was a daily spin-the-wheel game that rewarded participants with prizes on every play, ensuring consistent user engagement throughout the campaign. I designed the site to handle recurring traffic efficiently, ensuring smooth performance across different user sessions. Using MySQL, I tracked plays and prizes while preventing any misuse of the system, maintaining fairness in the promotion.',
    },
    {
        id: 2,
        title: 'API development',
        type: 'professional',
        description: "I designed and developed multiple APIs capable of managing hundreds of thousands of entries daily.",
        tags: ['PHP', 'MySQL', 'AWS', 'APIs'],
        img: API,
        case_study_overview: 'I was responsible for developing multiple APIs for significant projects, including MTN Dew’s gaming reward program, various Driscoll’s initiatives, and several other organizations. These APIs were specifically designed to manage user registrations and facilitate code redemption processes, ensuring efficient and reliable interactions for users across different platforms.',
        case_study: 'These APIs were designed to streamline user registrations and facilitate code redemption, ensuring secure authentication and a smooth onboarding experience. For MTN Dew, the API enabled users to easily sign up and claim rewards, while the Driscoll’s APIs supported promotional campaigns, allowing users to register for contests and redeem codes. I focused on best practices in API design, including clear documentation and rigorous testing, resulting in scalable and reliable solutions that enhanced user engagement across all projects.',
    },
    {
        id: 3,
        title: 'Infrastructure Upgrade',
        type: 'professional',
        description: 'A complete overhaul of the companies infrastructure.',
        tags: ['PHP', 'MySQL', 'AWS', 'Linux', 'Apache2', 'Docker', 'Kubernetes', 'Terraform'],
        img: devops,
        case_study_overview: 'I spearheaded a comprehensive infrastructure upgrade at my company, focusing on updating critical servers and machines to current, more secure versions. Many of these systems were significantly outdated, posing risks to performance and security. The upgrade involved not only modernizing these systems but also re-creating them with high availability and introducing new features to our internal sites.',
        case_study: 'A key component of this project was ensuring minimal downtime during the transition. I leveraged high-availability configurations to maintain business continuity, allowing services to remain online while the upgrades were implemented. To further enhance reliability, I integrated Icinga2 monitoring, enabling proactive detection and resolution of potential issues before they could affect operations. In addition to modernizing our infrastructure, I introduced new features across internal platforms to improve functionality and user experience. This project significantly enhanced system stability, performance, and maintainability, setting a solid foundation for future growth.',
    },
    {
        id: 4,
        title: 'Contact me',
        type: 'freelance',
        description: 'I am available to work for short-term projects',
        tags: [],
    },
];

const Projects = () => {
    const [activeType, setActiveType] = useState('professional');
    const [activeProject, setActiveProject] = useState(
        projects.find((project) => project.id === 1) // Default to project ID 1
    );

    // Update the active project when the active type changes
    useEffect(() => {
        const newProject = projects.find((project) => project.type === activeType);
        setActiveProject(newProject);
    }, [activeType]);

    const filteredProjects = projects.filter((project) => project.type === activeType);

    return (
        <div className="min-h-screen text-white p-8 overflow-hidden relative">
            <div className="max-w-6xl mx-auto relative z-0">
                <div className="flex justify-center mb-8 space-x-4">
                    <TypeSwitchButton
                        type="professional"
                        activeType={activeType}
                        onClick={setActiveType}
                    />
                    <TypeSwitchButton
                        type="freelance"
                        activeType={activeType}
                        onClick={setActiveType}
                    />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
                    {filteredProjects.map((project) => (
                        <ProjectItem
                            key={project.id}
                            project={project}
                            onClick={() => setActiveProject(project)}
                            isActive={activeProject?.id === project.id}
                        />
                    ))}
                </div>

                {activeProject && (
                    activeProject.type === 'freelance' ? (
                        <FREELANCE_PROJECTS />
                    ) : (
                        <ProjectDetails project={activeProject} />
                    )
                )}
            </div>
        </div>
    );
};

const TypeSwitchButton = ({ type, activeType, onClick }) => (
    <button
        className={`px-6 py-2 rounded-full font-semibold transition-all duration-300 ${
            activeType === type ? 'bg-blue-900 text-white' : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
        }`}
        onClick={() => onClick(type)}
    >
        {type === 'professional' ? 'Professional Projects' : 'Freelance Work'}
    </button>
);

const ProjectItem = ({ project, onClick, isActive }) => (
    <div
        className={`cursor-pointer p-6 rounded-lg transition-all duration-300 ${
            isActive ? 'bg-blue-900 text-white' : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
        }`}
        onClick={() => onClick(project)}
    >
        <h3 className="text-xl font-semibold mb-2">{project.title}</h3>
        <p className="text-sm mb-4 opacity-80">{project.description}</p>
        <div className="flex flex-wrap gap-2">
            {project.tags.map((tag, index) => (
                <span
                    key={index}
                    className={`px-2 py-1 rounded-full text-xs ${
                        isActive ? 'bg-[#2A4A2F] text-white' : 'bg-gray-700 text-gray-300'
                    }`}
                >
                    {tag}
                </span>
            ))}
        </div>
    </div>
);

const ProjectDetails = ({ project }) => (
    <div className="bg-blue-900 rounded-lg p-8 mt-8 text-white shadow-lg">
        <h2 className="text-3xl font-bold mb-4">{project.title}</h2>
        <p className="mb-6 text-gray-300">{project.description}</p>
        <div className="flex flex-wrap gap-3">
            {project.tags.map((tag, index) => (
                <span key={index} className="px-3 py-1 bg-[#355E3B] rounded-full text-sm text-white">
                    {tag}
                </span>
            ))}
        </div>
        {project.img && (
            <img className="mt-6 rounded-lg shadow-md" src={project.img} alt={project.title} />
        )}
        <div className="mt-6">
            <p>{project.case_study_overview}</p>
            <p className="mt-6">{project.case_study}</p>
        </div>
    </div>
);

export default Projects;
