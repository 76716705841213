import React, { useState, useEffect } from 'react';

const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;

export default function FREELANCE_PROJECTS() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submissionMessage, setSubmissionMessage] = useState('');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://cdn.jsdelivr.net/npm/@emailjs/browser@4/dist/email.min.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.emailjs) {
        window.emailjs.init(PUBLIC_KEY);
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('service_id', SERVICE_ID);
    formData.append('template_id', TEMPLATE_ID);
    formData.append('user_id', PUBLIC_KEY);
    formData.append('email', email);
    formData.append('message', message);

    try {
      const response = await fetch('https://api.emailjs.com/api/v1.0/email/send-form', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        setSubmissionMessage('Thank you for reaching out! Your email has been sent, and I will get back to you shortly.');
        setEmail('');
        setMessage('');
      } else {
        // const errorData = await response.json();
        setSubmissionMessage(`Oops... An error has occured`);
      }
    } catch (error) {
      setSubmissionMessage(`Oops... An error has occured`);
    }
  };

  return (
    <div className="bg-gray-800 py-16 sm:py-24">
      <div className="mx-auto max-w max-h sm:px-6 lg:px-8">
        <div className="rounded-xl relative isolate overflow-hidden bg-gray-900 px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-24 xl:py-32">
          {submissionMessage ? (
            <h2 className="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">
              {submissionMessage}
            </h2>
          ) : (
            <>
              <h2 className="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Let’s work together.
              </h2>
              <p className="mx-auto mt-2 max-w-xl text-center text-lg leading-8 text-gray-300">
                {`Let’s take your project to the next level. Contact me to see how my freelance work can make an impact.`}
              </p>
            </>
          )}
          <form onSubmit={handleSubmit} id="emailform" className="mx-auto mt-10 flex flex-col max-w-md gap-y-4">
            <label htmlFor="email-address" className="sr-only">
              Email address
            </label>
            <input
              id="email-address"
              name="email"
              type="email"
              required
              placeholder="Enter your email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
            />

            <label htmlFor="message" className="sr-only">
              Message...
            </label>
            <input
              id="message"
              name="message"
              type="text"
              required
              placeholder="Message..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="min-w-0 rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
            />

            <button
              type="submit"
              className="flex-none rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Contact me
            </button>
          </form>
          <svg
            viewBox="0 0 1024 1024"
            aria-hidden="true"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2"
          >
            <circle r={512} cx={512} cy={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
            <defs>
              <radialGradient
                r={1}
                cx={0}
                cy={0}
                id="759c1415-0410-454c-8f7c-9a820de03641"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(512 512) rotate(90) scale(512)"
              >
                <stop stopColor="#a2d675" />
                <stop offset={1} stopColor="#00b509" stopOpacity={0} />
              </radialGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
}
